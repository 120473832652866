.sidebar__menu {
  position: relative;
}
.sidebar__menu__item {
  display: flex;
  align-items: center;
  place-content: flex-start;
  padding: 1rem 3rem;
  font-size: 1.1rem;
  font-weight: 500;
  color: #555;
  transition: color 0.3s ease-in-out;
}
.sidebar__menu__item.active {
  color: #fff;
}
.sidebar__menu__item__icon {
  margin-right: 1rem;
}
.sidebar__menu__item__icon i {
  font-size: 1.75rem;
}
.sidebar__menu__indicator {
  position: absolute;
  top: 0;
  left: 50%;
  width: calc(100% - 40px);
  border-radius: 10px;
  background-color: #71c0bb;
  z-index: -1;
  transform: translateX(-50%);
  transition: 0.3s ease-in-out;
}
